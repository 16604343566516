body {
    font-family: 'Open Sans',Helvetica,Arial,sans-serif;
    background-color: black;
    position: absolute; 
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
}
#fullscreen {
    z-index: -999;
    min-height: 100%;
    min-width: 1024px;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
}
#avatar {
    height: 500px;
    border-radius: 100%;
    border: 5px solid black;
    margin-bottom: 40px;
}
h1 {
    color: white;
    font-weight: bold;
    letter-spacing: 0.1em;
    font-size: 4em;
    font-family: 'Open Sans';
    margin: -10px 0;
}
h3 {
    color: white;
    padding-bottom: 20px;
    font-weight: thin;
}
.content {
    padding-top: 100px;
    padding-bottom: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
}
ul {
    list-style: none;
    padding: 0;
}
li {
    border-radius: 100%;
    border: 2px solid white;
    color: white;
    margin: 8px;
    display: inline-block;
    list-style: none;
    width: 65px;
    height: 65px;
}
li:hover {
    color: white;
    text-decoration: none;
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    transition: 500ms;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.5);
}
a {
    color: white;
    padding: 15px;
    text-decoration: none;
}
.description {
    display: none;
}

@media (max-width: 450px) {
ul {
    text-align: center;
}
li {
    border-radius: 0%;
    border: none;
    color: white;
    margin: 8px;
    display: block;
    list-style: none;
    margin: 10px 0px;
    text-align: center;
    height: auto;
    width: auto;
}
li:hover {
    background-color: none;
    transition: none;
}
a {
    text-align: justify;
    border: 2px solid white;
    border-radius: 5px;
    color: white;
    padding: 15px;
    padding-left: 30px;
    width: 70%;
}
a:hover {
    -webkit-transition: 500ms;
    -moz-transition: 500ms;
    transition: 500ms;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    text-decoration: none;
}
.description {
    display: inline-block;
    color: white;
    font-family: Arial;
    margin-left: 40px;
}
.content {
    padding-top: 60px;
}
}